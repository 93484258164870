<template>
  <SchedulesStyled>
    <PanelTableMolecule
      :isLoading="loading"
      :count="totalCount"
      :title="$tc('schedule', 2) | capitalize"
      :columns="columns"
      :rows="tableData"
      :pageIndex="page + 1"
      :pageSize="perPage"
      @pageNumberChange="pageNumberChange($event)"
      @reload="reload"
    />
    <div class="misc">
      <router-link class="btn" :to="{ name: 'RescheduleJobs' }">Reschedule Jobs</router-link>
    </div>
    <router-view />
  </SchedulesStyled>
</template>

<script>
import { styled } from '@egoist/vue-emotion'
import get from 'lodash/get'
import { getRolesFromToken } from '@common/utils'
import { Edit2Icon, SearchIcon, TrashIcon, CheckIcon } from 'vue-feather-icons'

import SCHEDULES_QUERY from '#/graphql/operations/calendars/schedulesQuery.graphql'
import DELETE_SCHEDULE_MUTATION from '#/graphql/operations/calendars/deleteScheduleMutation.graphql'

import PanelTableMolecule from '@/components/Atomic/Molecules/PanelTableMolecule'
import TableColumnSearch from '@/components/Atomic/Molecules/TableColumnSearch'
import EntityLinkAtom from '@/components/Atomic/Atoms/EntityLinkAtom'
import { DateTime } from 'luxon'

const SchedulesStyled = styled('div')`
  margin: 1rem;
  display: grid;
  grid-template-columns: 1fr;
  grid-auto-rows: min-content;
  grid-gap: 1rem;
  .misc {
    display: flex;
    justify-content: flex-end;
  }
  .actions {
    display: flex;
    flex-direction: row;
    padding: 5px;
    justify-content: space-around;

    color: ${p => p.theme.colors.primary};

    > div {
      cursor: pointer;
      &:hover {
        color: ${props => props.theme.colors.white};
      }
    }
  }
`

export default {
  components: {
    SchedulesStyled,
    PanelTableMolecule,
  },
  data() {
    return {
      loading: true,
      count: 0,
      totalCount: 0,
      models: [],
      errors: [],
      searchName: '',
      searchCalendarName: '',
      where: undefined,
      perPage: 10,
      page: 0,
      selectedOwnerType: null,
    }
  },
  computed: {
    userRoles() {
      return getRolesFromToken(this.$keycloak.token)
    },
    hasWritePermission() {
      return this.userRoles.includes('archon') || this.userRoles.includes('talpa-product')
    },
    totalPages() {
      return Math.ceil(this.totalCount / this.perPage)
    },
    skip() {
      return this.page * this.perPage
    },
    columns() {
      const header = [
        {
          field: 'name',
          key: 'name',
          title: `Name`,
          align: 'left',
          renderBodyCell: ({ row }) => {
            const { id, type, name } = row
            if (!id || !type || !name) {
              return `something missing`
            }
            return <EntityLinkAtom type={type} id={id} label={name} />
          },
          filterCustom: {
            defaultVisible: false,
            render: ({ closeFn }) => {
              return (
                <TableColumnSearch
                  searchValue={this.searchName}
                  placeholder="Search name"
                  on-valueChange={e => (this.searchName = e)}
                  on-cancel={() => this.searchCancel(closeFn)}
                  on-confirm={() => this.searchConfirm(closeFn)}
                />
              )
            },
            // custom filter icon
            filterIcon: () => {
              return <SearchIcon size="1.3x" />
            },
          },
        },
        {
          field: 'calendar',
          key: 'calendar',
          title: `${this.$tc('calendar', 1)}`,
          align: 'center',
          renderBodyCell: ({ row }) => {
            const { id, type, label } = row?.calendar
            if (!id || !type || !label) {
              return `something missing`
            }
            return <EntityLinkAtom type={type} id={id} label={label} />
          },
          filterCustom: {
            defaultVisible: false,
            render: ({ closeFn }) => {
              return (
                <TableColumnSearch
                  searchValue={this.searchCalendarName}
                  placeholder="Search calendar name"
                  on-valueChange={e => (this.searchCalendarName = e)}
                  on-cancel={() => this.searchCalendarCancel(closeFn)}
                  on-confirm={() => this.searchCalendarConfirm(closeFn)}
                />
              )
            },
            // custom filter icon
            filterIcon: () => {
              return <SearchIcon size="1.3x" />
            },
          },
        },
        {
          field: 'timezone',
          key: 'timezone',
          title: `Timezone`,
          align: 'center',
        },
        {
          field: 'useTimezone',
          key: 'useTimezone',
          title: 'Use tz v2',
          renderBodyCell: ({ row }) => {
            return row.useTimezone ? <CheckIcon /> : '-'
          },
        },
        {
          field: 'ownerType',
          key: 'ownerType',
          title: `${this.$tc('owner', 1)} ${this.$tc('type', 1)}`,
          align: 'center',
          filter: {
            filterList: this.availableOwnerTypes,
            isMultiple: true,
            // filter confirm hook
            filterConfirm: filterList => {
              const selectedIds = filterList.filter(x => x.selected).map(x => x.value)
              this.selectedOwnerType = selectedIds.length > 0 ? selectedIds : null
            },
            // filter reset hook
            filterReset: () => {
              this.selectedOwnerType = null
            },
            filterIcon: () => {
              return <SearchIcon size="1.3x" />
            },
          },
        },
        {
          field: 'owner',
          key: 'owner',
          title: `${this.$tc('owner', 1)}`,
          align: 'center',
          renderBodyCell: ({ row }) => {
            const { id, type, label } = row?.owner
            if (!id || !type || !label) {
              return `something missing`
            }
            return <EntityLinkAtom type={type} id={id} label={label} />
          },
          // filterCustom: this.canSearchOwner
          //   ? {
          //       defaultVisible: false,
          //       render: ({ closeFn }) => {
          //         return (
          //           <TableColumnSearch
          //             searchValue={this.searchOwnerName}
          //             placeholder="Search owner name"
          //             on-valueChange={e => (this.searchOwnerName = e)}
          //             on-cancel={() => this.searchCancelOwner(closeFn)}
          //             on-confirm={() => this.searchConfirmOwner(closeFn)}
          //           />
          //         )
          //       },
          //       // custom filter icon
          //       filterIcon: () => {
          //         return <SearchIcon size="1.3x" />
          //       },
          //     }
          //   : undefined,
        },
        {
          field: 'job',
          key: 'nextJob',
          title: `Next job`,
          align: 'center',
          renderBodyCell: ({ row }) => {
            if (!row.nextJob) {
              return `-`
            }
            const { id, __typename } = row?.nextJob || {}
            if (!id || !__typename) {
              return `something missing`
            }
            return <EntityLinkAtom type={__typename} id={id} />
          },
        },
        {
          field: 'nextJobShouldRunAt',
          key: 'nextJobShouldRunAt',
          title: 'next run (planned time)',
          renderBodyCell: ({ row }) => {
            const runAt = row?.nextJob?.runAt

            if (runAt) {
              return `${DateTime.fromISO(runAt).toLocaleString(DateTime.DATETIME_SHORT)}`
            }
            return `-`
          },
        },
        {
          field: 'lastJobShouldRunAt',
          key: 'lastJobShouldRunAt',
          title: 'last run (planned time)',
          align: 'center',
          renderBodyCell: ({ row }) => {
            const runAt = row?.lastJobShouldRunAt
            if (runAt) {
              return `${DateTime.fromISO(runAt).toLocaleString(DateTime.DATETIME_SHORT)}`
            }
            return `-`
          },
        },
        {
          field: 'lastJobDidRunAt',
          key: 'lastJobDidRunAt',
          title: 'last run (actual run)',
          align: 'center',
          renderBodyCell: ({ row }) => {
            const runAt = row?.lastJobDidRunAt
            if (runAt) {
              return `${DateTime.fromISO(runAt).toLocaleString(DateTime.DATETIME_SHORT)}`
            }
            return `-`
          },
        },
        {
          field: 'actions',
          key: 'i',
          title: 'Actions',
          width: '',
          center: 'left',
          renderBodyCell: ({ row }) => {
            return (
              <div class="actions">
                <div on-click={() => this.confirmDelete(row)}>
                  <TrashIcon size="1.3x" />
                </div>
                <div on-click={() => this.$router.push({ name: 'EditSchedule', params: { id: row?.rowKey } })}>
                  <Edit2Icon size="1.3x" />
                </div>
              </div>
            )
          },
        },
      ]
      return header
    },
    tableData() {
      const tableData = this.models.map(schedule => {
        return {
          name: schedule.name,
          calendar: {
            type: 'Calendar',
            id: schedule?.calendar?.id,
            label: schedule?.calendar?.name,
          },
          timezone: schedule.timezone,
          nextJob: schedule.nextJob,
          lastJobShouldRunAt: schedule.lastJobShouldRunAt,
          lastJobDidRunAt: schedule.lastJobDidRunAt,
          lastJobId: schedule.lastJobId,
          useTimezone: schedule?.useTimezoneInRSchedule,
          ownerType: schedule?.calendar?.owner?.__typename,
          owner: {
            type: schedule.calendar?.owner?.__typename,
            id: schedule.calendar?.owner?.id,
            label: schedule.calendar?.owner?.name,
          },
          ownerName: schedule.calendar?.owner?.name,
          recurrences: schedule.recurrences.length,
          rowKey: schedule.id,
          id: schedule.id,
          type: schedule.__typename,
        }
      })
      return tableData
    },
    canSearchOwner() {
      return this.selectedOwnerType?.length === 1 && this.selectedOwnerType[0] === 'Subsidiary'
    },
    availableOwnerTypes() {
      return [
        {
          value: 'Organization',
          label: 'Organization',
          selected: false,
        },
        {
          value: 'Subsidiary',
          label: 'Subsidiary',
          selected: false,
        },
        {
          value: 'User',
          label: 'User',
          selected: false,
        },
      ]
    },
  },
  methods: {
    reload() {
      this.where = ''
      this.selectedOwnerType = null
      this.$apollo.queries.models.refetch()
    },
    pageNumberChange(pageIndex) {
      this.page = pageIndex - 1
    },
    searchCancel(closeFn) {
      this.searchName = ''
      closeFn()
    },
    searchConfirm(closeFn) {
      closeFn()
    },
    searchCalendarCancel(closeFn) {
      this.searchCalendarName = ''
      closeFn()
    },
    searchCalendarConfirm(closeFn) {
      closeFn()
    },
    search() {
      let AND = []
      if (this.searchName) {
        AND.push({
          name: {
            contains: this.searchName,
            mode: 'insensitive',
          },
        })
      }
      if (this.searchCalendarName) {
        AND.push({
          calendar: {
            is: {
              name: {
                contains: this.searchCalendarName,
                mode: 'insensitive',
              },
            },
          },
        })
      }
      if (this.selectedOwnerType) {
        AND.push({
          calendar: {
            is: {
              ownerType: {
                in: this.selectedOwnerType,
              },
            },
          },
        })
      }
      if (AND.length > 0) {
        this.where = { AND }
      } else {
        this.where = undefined
      }
    },
    confirmDelete(model) {
      this.$root.$emit(
        'activateOverlay',
        'ConfirmDeleteOverlay',
        {
          type: 'Schedule',
          instance: model,
          labelKey: 'name',
          onConfirm: this.delete,
          onConfirmArgs: model,
        },
        this.onCloseSettings,
      )
    },
    async delete({ id }) {
      const res = await this.$apollo.mutate({
        mutation: DELETE_SCHEDULE_MUTATION,
        variables: {
          where: {
            id,
          },
        },
      })
      if (res) {
        this.$root.$emit('closeOverlay')
        // this.$emit('reload')
        this.reload()
      }
      this.$root.$emit('closeOverlay')
    },
  },
  watch: {
    searchName: {
      handler(s) {
        this.page = 0
        if (s) {
          this.searchName = s
        } else {
          this.searchName = undefined
        }
        this.search()
      },
    },
    searchCalendarName: {
      handler(s) {
        this.page = 0
        if (s) {
          this.searchCalendarName = s
        } else {
          this.searchCalendarName = undefined
        }
        this.search()
      },
    },
    selectedOwnerType: {
      handler() {
        this.page = 0
        this.search()
      },
    },
  },
  apollo: {
    models: {
      query: SCHEDULES_QUERY,
      variables() {
        return {
          take: this.perPage,
          skip: this.skip,
          where: this.where ? this.where : undefined,
        }
      },
      manual: true,
      result({ data, loading }) {
        this.loading = loading
        this.models = data?.schedules
        this.totalCount = data?.aggregateSchedule?._count?.id
        this.count = get(data, 'aggregateSchedule._count._all', 0)
        this.totalCount = get(data, 'aggregateSchedule._count._all', 0)
      },
    },
  },
}
</script>
