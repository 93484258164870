var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('SchedulesStyled', [_c('PanelTableMolecule', {
    attrs: {
      "isLoading": _vm.loading,
      "count": _vm.totalCount,
      "title": _vm._f("capitalize")(_vm.$tc('schedule', 2)),
      "columns": _vm.columns,
      "rows": _vm.tableData,
      "pageIndex": _vm.page + 1,
      "pageSize": _vm.perPage
    },
    on: {
      "pageNumberChange": function pageNumberChange($event) {
        return _vm.pageNumberChange($event);
      },
      "reload": _vm.reload
    }
  }), _c('div', {
    staticClass: "misc"
  }, [_c('router-link', {
    staticClass: "btn",
    attrs: {
      "to": {
        name: 'RescheduleJobs'
      }
    }
  }, [_vm._v("Reschedule Jobs")])], 1), _c('router-view')], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }